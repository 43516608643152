import React from "react";
import "./style.scss";
import { Link } from "gatsby";

const List = ({ data }) => {
  const result = data.filter(checkImage);

  function checkImage(element) {
    return element.image;
  }

  const list = result ? [result[0], result[1], result[2]] : [];

  const truncate = (str, max, suffix) => {
    if (!str) {
      str = "";
      max = 0;
    }
    return str.length < max ? str : `${str.substr(0, str.substr(0, max - suffix.length).lastIndexOf(" "))}${suffix}`;
  };

  function addStr(str, index, stringToAdd) {
    if (!str) {
      str = "";
      index = 0;
    }
    return str.substring(0, index) + stringToAdd + str.substring(index, str.length);
  }

  return (
    <div className="home-list">
      {/* <pre>{JSON.stringify({ list, rrr: data }, null, 2)}</pre> */}
      <div className="list">
        <div className="list-head">
          <span>Editor's Picks</span>
        </div>
        {list.map((element) => {
          return (
            <Link to={`/${element?.slug}`}>
              <div className="list-item">
                <div className="list-item-image">
                  <figure className="image hl-image">
                    {element?.image.includes("idc") ? <img src={addStr(element?.image, 65, "360x250_")} /> : <img src={addStr(element?.image, 37, "360x250_")} />}
                  </figure>
                </div>

                <div className="list-item-content">
                  <div className="list-item-title">{element?.title}</div>
                  <div className="list-item-category">
                    <span>{element?.categories[0]?.name}</span>
                  </div>
                  <div className="list-item-description">{truncate(element?.description?.replace(/<[^>]+>/g, ""), 150, "...")}</div>
                </div>
              </div>
            </Link>
          );
        })}
      </div>
    </div>
  );
};
export default List;

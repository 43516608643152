import React from "react";
import "./style.scss";
import Hero from "./components/hero/hero";
import List from "./components/list/list";
import List2 from "./components/list/List2";
import Sidebarone from "./components/sidebar/Sidebarone";
import Bigsection from "./components/Bigsection/Bigsection";
import Sidebar from "../main/sidebar/sidebar";

const Home = ({ data }) => {
  let temp = data?.cms?.recent_story.concat(data?.cms?.recent_listicle);
  let list = data?.cms?.recent_listicle.concat(data?.cms?.recent_story);
  // let filteredData = temp?.filter(function (el) {
  //   return el?.image?.length > 0;
  // });

  return (
    <div className="home-1">
      <div className="container is-max-widescreen mt-2">
        <Hero data={list} />
        <div className="columns m-0">
          <div className="column is-two-thirds">
            <List data={temp} />
          </div>
          <div className="column mt-6 mr-3 ml-3">
            <Sidebarone data={temp} />
          </div>
        </div>
      </div>
      <Bigsection data={list} />
      <div className="container is-max-widescreen mt-2">
        <div className="columns m-0">
          <div className="column is-two-thirds">
            {console.log({ ander: temp })}
            <List2 data={temp} />
          </div>
          <div className="column">
            <Sidebar recent_story={temp} />
          </div>
        </div>
      </div>
    </div>
  );
};
export default Home;

import React from "react";
import "./style.scss";
import { Link } from "gatsby";

const Bigsection = ({ data }) => {
  let tech_story = data.filter(checkImage);

  function checkImage(element) {
    return element.image;
  }

  function addStr(str, index, stringToAdd) {
    if (!str) {
      str = "";
      index = 0;
    }
    return str.substring(0, index) + stringToAdd + str.substring(index, str.length);
  }

  const list = data ? [tech_story[0], tech_story[1], tech_story[2]] : [];
  const list2 = data ? [tech_story[7], tech_story[8], tech_story[12]] : [];

  return (
    <div className="big-section mt-6">
      <div className="container is-max-widescreen mt-2">
        <div className="big-section-header">
          <div className="title">Listicles</div>
        </div>
        <div className="tile is-ancestor">
          <div className="tile is-parent is-vertical">
            <div className="tile is-parent">
              {list.map((element) => {
                return (
                  <div className="tile hfb-1">
                    <article
                      className="tile is-child hfb-1"
                      style={{
                        backgroundImage: `url(${element?.image.includes("idc") ? addStr(element?.image, 65, "400x400_") : addStr(element?.image, 37, "400x400_")})`,
                      }}
                    >
                      <Link to={`/${element?.slug}`}>
                        <div className="hfb-details">
                          <div className="title">
                            <div className="category">
                              <span>{element?.categories[0]?.name}</span>
                            </div>
                            {element?.title}
                          </div>
                        </div>
                      </Link>
                    </article>
                  </div>
                );
              })}
            </div>

            <div className="tile is-parent">
              {list2.map((element) => {
                return (
                  <div className="tile hfb-2">
                    <article
                      className="tile is-child hfb-2"
                      style={{
                        backgroundImage: `url(${element?.image.includes("idc") ? addStr(element?.image, 65, "400x400_") : addStr(element?.image, 37, "400x400_")})`,
                      }}
                    >
                      <Link to={`/${element?.slug}`}>
                        <div className="hfb-details">
                          <div className="title">
                            <div className="category">
                              <span>{element?.categories[0]?.name}</span>
                            </div>
                            {element?.title}
                          </div>
                        </div>
                      </Link>
                    </article>
                  </div>
                );
              })}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
export default Bigsection;
